import sendForm from '@scripts/modules/sendForm/sendForm';

const submitBtn = document.querySelector('[type="submit"]')
const popupFormTitle = document.querySelector('.form-title')
const popupFormSubject = document.querySelector('.ui-form [type="hidden"]')

document.querySelectorAll('.btn_form').forEach(item => {
   item.addEventListener('click', function () {
      let attrName = this.dataset.name
      let attrTitle = this.dataset.title

      if (attrName && attrTitle) {
         popupFormTitle.innerText = attrTitle
         popupFormSubject.value = attrName
      } else {
         popupFormTitle.innerText = 'Свяжитесь с нами'
         popupFormSubject.value = 'Сообщение из формы'
      }
   })
})




submitBtn.addEventListener('click', function () {
   grecaptcha.ready(function () {
      grecaptcha.execute('6Le8wBIqAAAAABBYrjYPoXdz_G6_jxt2FTT9ilkn', { action: 'submit' }).then(function (token) {
         onSubmit(token);
      });
   });
});

async function onSubmit(token) {
   const form = document.querySelector('.ui-form');
   await sendForm(form, token);
}

document.querySelector('.ui-form').addEventListener('submit', function (e) {
   e.preventDefault();
});