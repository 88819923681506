import openPopup from '@scripts/modules/Popup'

let videoProduct = `
	<iframe loading="lazy" width="560" height="315" src="https://www.youtube.com/embed/PkDgq0mG8mg" title="YouTube video player"
	frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
	allowfullscreen></iframe>
`

let options = {
	popup: '#video',
	open: '.btn_video',
	close: '#video .ui-close',
	overlay: '#video .ui-overlay',
	whereInsert: '#video-wrap',
	frame: videoProduct
}

new openPopup(options)