// import $ from 'jquery';s

export default function Accordion ({ visible, collapse, onlyOneOpened = false}) {
	
		$(collapse).hide()

		$(visible).on('click', function () {
			let self = $(this)

			if (!onlyOneOpened) {
				self.toggleClass('active').next().slideToggle()
			} else {
				$(visible).not(self).removeClass('active').next().slideUp()
				self.toggleClass('active').next().slideToggle()
			}
		})
	
}