export default class MobilePopupHandler {
   constructor ({ selector, elClose, elOpen = null, overlay, openByEvent = false }) {
      this.selector = document.querySelector(selector);
      this.elClose = document.querySelectorAll(elClose);
      this.elOpen = elOpen ? document.querySelectorAll(elOpen) : null;
      this.overlay = document.querySelector(overlay);
      this.openByEvent = openByEvent;

      this.body = document.body;

      this.setup();
   }

   setup() {
      this.openByEvent ? this.open() : this.close();

      this.elOpen && this.eventHandler('click', this.elOpen, this.open.bind(this));
      this.overlay && this.eventHandler('click', this.overlay, this.close.bind(this));
      this.elClose && this.eventHandler('click', this.elClose, this.close.bind(this));
      
      this.eventHandler('keydown', this.body, (e) => {
         if (e.keyCode === 27) {
            this.close.call(this);
         }
      });
   }

   open() {
      this.handler('open', 'active', this.selector);
      this.handler('open', 'active', this.overlay);
      this.handler('open', 'no-scroll', this.body);
   }

   close() {
      this.handler('close', 'active', this.selector);
      this.handler('close', 'active', this.overlay);
      this.handler('close', 'no-scroll', this.body);
   }

   handler(action, className, element) {
      if (element) {
         if (action === 'open') {
            element.classList.add(className);
         } else if (action === 'close') {
            element.classList.remove(className);
         }
      }
   }

   eventHandler(event, element, callback) {
      if (element) {
         if (element.length > 0) {
            element.forEach((el) => {
               if (el && typeof el.addEventListener === "function") {
                  el.addEventListener(event, callback);
               }
            });
         } else {
            if (typeof element.addEventListener === "function") {
               element.addEventListener(event, callback);
            }
         }
      }
   }
}