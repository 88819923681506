// import $ from 'jquery'
import Accordion from '@scripts/modules/Accordion'

function setOptions (selector, onlyOneOpened = false) {
	return {
		visible: `${selector} .ui-accordion__visible`,
		collapse: `${selector} .ui-accordion__collapse`,
		onlyOneOpened
	}
}

const specifications = new Accordion(setOptions('#specifications'))
const faq = new Accordion(setOptions('#faq', true))