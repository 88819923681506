import { formPopup } from '@partials/getInTouch/getInTouch';
import Notification from '@scripts/modules/Notification';
import successImage from '@images/success.svg';
import validateForm from './validateForm'


const noteSuccessContent = `
   <h5 class="note-title">
      <img src="${successImage}", alt="Success">
      <span>Данные успешно отправлены</span>
   </h5>

   <p class="note-text">Спасибо! Мы получили ваше сообщение и свяжемся с вами в ближайшее время</p>
   <button class="ui-btn note-btn flex_c_c"><span>Ок, закрыть</span></button>
`

export default async function sendForm(form, token) {
   const statusMessage = form.querySelector('.message-status');

   try {
      const isValid = validateForm(form);
      if (isValid) {
         const phpHandlerUrl = "libs/telegram.php";

         const formData = new FormData(form);
         formData.append('g-recaptcha-response', token);

         const response = await fetch(phpHandlerUrl, {
            method: "POST",
            body: formData,
         });

         if (response.ok) {
            for (let item of document.querySelectorAll('.ui-form .ui-field')) {
					item.value = ''
				}
            formPopup.onClose()

            // Notification - успещная отрпавка формы
            const successNote = new Notification({ className: 'note_success', content: noteSuccessContent })
            successNote.onOpen()
            document.querySelector('#notification .note-btn').addEventListener('click', successNote.onClose)

            // Цель для Yandex.Метрика
            ym(97551553, 'reachGoal', 'clickButton')
            
         } else {
            statusMessage.classList.add('error')
            statusMessage.innerText = 'Ошибка. Форма не отправлена'
         }
      }
   } catch (error) {
      statusMessage.classList.add('error')
      statusMessage.innerText = 'Ошибка. Форма не отправлена'
   }
}

