import openPopup from '@scripts/modules/Popup'
  
const formPopup = new openPopup({
   popup: '#getInTouch',
   open: '.btn_form',
   close: '#getInTouch .ui-close',
   overlay: '#getInTouch .ui-overlay',
});

export {
   formPopup
}