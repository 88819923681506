// import $ from 'jquery'

// const sizesLink = document.querySelector('a[href="#sizes"]')
// const sizesItem = document.getElementById('sizes')

// sizesLink.addEventListener('click', () => {
// 	sizesItem.classList.add('active')
// 	sizesItem.slideDown()
// })

$(document).ready(function() {
	$('a[href="#sizes"]').on('click', function(event) {
		// event.preventDefault();
		$('#sizes').addClass('active').next().slideDown();
	});
});