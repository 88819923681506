window.addEventListener('load', () => {
   fadeInNavOnScroll('.sticky', 150)
})

window.addEventListener('scroll', () => {
   fadeInNavOnScroll('.sticky', 150)
})


function fadeInNavOnScroll(elements, distance) {
   let items = document?.querySelectorAll(elements)

   for (let i = 0; i < items.length; i++) {
      if (window.scrollY >= distance) {
         items[i].classList.add('scroll')

         if (items[i].tagName.toLowerCase() !== 'header') {
            items[i].classList.add('active')
         } else {
            setTimeout(() => {
               items[i].classList.add('active')
            }, 100)
         }
      }
         
      
      if (window.scrollY <= distance) {
         items[i].classList.remove('scroll')
         items[i].classList.remove('active')
      }
         
   }
}